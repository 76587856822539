<template>
  <div class="component-wrap">
    <div class="tabs">
      <a-tabs v-model:activeKey="currentTab">
        <!-- 个人信息 -->
        <a-tab-pane :key="1" :tab="$t('login.personal_information')">
          <a-form
            class="form-box"
            name="custom-validation"
            ref="formRef_userInfo"
            size="large"
            :model="userInfo"
            :labelCol="{ span: 2 }"
            :wrapperCol="{ span: 11 }"
            @finish="saveUserInfo"
          >
            <!-- 头像 -->
            <a-form-item :label="$t('login.avatar')" :colon="false" name="file">
              <div class="avatar-box">
                <img :src="imageUrl" />
                <input
                  class="up-input"
                  ref="uploadInput"
                  type="file"
                  accept="image/jpg, image/jpeg, image/png, image/gif"
                  @change="selectFile"
                />
                <div class="edit">{{ $t("cm_modify") }}</div>
                <!-- 修改 -->
              </div>
            </a-form-item>
            <!-- 姓名 -->
            <a-form-item
              :label="$t('login.name')"
              :colon="false"
              name="realName"
            >
              <OpenData
                type="userName"
                :openid="realName"
              />
            </a-form-item>
            <!-- 帐号 -->
            <a-form-item
              :label="$t('login.账号')"
              :colon="false"
            >
              <a-tooltip>
                <template #title>{{ $t('login.点击复制') }}</template>
                <span  @click="copyText">{{ account}}</span>
              </a-tooltip>
            </a-form-item>
            <!-- 部门 -->
            <a-form-item
              :label="$t('CM_Department')"
              :colon="false"
              name="departmentName"
            >
              <OpenData
                type="departmentName"
                :openid="departmentName"
              />
            </a-form-item>          
            <!-- 岗位 -->
            <a-form-item :colon="false" :label="$t('Pub_Lab_Post')">
              {{ positionName }}
            </a-form-item>
            <!-- 邮箱 -->
            <a-form-item
              :label="$t('login.email')"
              :colon="false"
              name="email"
              :rules="[{ validator: validatorEmail }]"
            >
              <a-input
                v-model:value="userInfo.email"
                size="large"
                :placeholder="$t('login.email_p')"
              />
              <!-- 请输入邮箱 -->
            </a-form-item>
            <!-- 性别 -->
            <a-form-item
              :label="$t('teacher.sex')"
              :colon="false"
              name="gender"
            >
              <a-radio-group v-model:value="gender">
                <a-radio :value="1">{{ $t("teacher.man_woman", 1) }}</a-radio>
                <!-- 男 -->
                <a-radio :value="2">{{ $t("teacher.man_woman", 2) }}</a-radio>
                <!-- 女 -->
              </a-radio-group>
            </a-form-item>
            <!-- 出生日期 -->
            <a-form-item
              :label="$t('login.birthday')"
              :colon="false"
              name="birthday"
            >
              <a-date-picker
                v-model:value="birthday"
                type="date"
                size="large"
                :placeholder="$t('login.birthday_p')"
                format="YYYY-MM-DD"
                @change="selectDate"
                style="width: 100%"
              />
              <!-- 请选择出生日期期 -->
            </a-form-item>
            <!-- 手机号 -->
            <a-form-item
              :label="$t('login.mobile')"
              :colon="false"
              name="mobile"
              :rules="[{ validator: validatorMobile }]"
            >
              <a-input
                v-model:value="userInfo.mobile"
                size="large"
                :placeholder="$t('login.请输入手机号')"
              />
              <!-- 请输入手机号 -->
            </a-form-item>
            <!-- 座机 -->
            <a-form-item
              :label="$t('login.landline')"
              :colon="false"
              name="tel"
              v-if="1==2"
              :rules="[{ validator: validatorTel }]"
            >
              <a-input
                v-model:value="userInfo.tel"
                size="large"
                :placeholder="$t('login.landline_p')"
              />
              <!-- 请输入座机 -->
            </a-form-item>
            <div class="btns">
              <a-button
                class="btn"
                type="primary"
                html-type="submit"
                :loading="submitting"
                >{{ $t("login.save_submit") }}</a-button
              >
              <!-- 保存并提交 -->
            </div>
          </a-form>
        </a-tab-pane>
        <!-- 修改密码 -->
        <a-tab-pane
          :key="2"
          :tab="$t('CM_ModifyPassword')"
          v-if="hasPwd && !ISHTSY"
        >
          <a-form
            class="form-box pwd"
            name="custom-validation"
            ref="formRef"
            :model="formState"
            :rules="rules"
            :labelCol="{ span: 3 }"
            :wrapperCol="{ span: 8 }"
            @finish="updatePassword"
          >
            <!-- 原密码 -->
            <a-form-item
              has-feedback
              :label="$t('login.old_password')"
              :colon="false"
              name="oldpass"
            >
              <a-input-password
                v-model:value="formState.oldpass"
                type="password"
                size="large"
                autocomplete="off"
              />
            </a-form-item>
            <!-- 新密码 -->
            <a-form-item
              has-feedback
              :label="$t('login.new_password')"
              :colon="false"
              name="newpass"
            >
              <a-input-password
                v-model:value="formState.newpass"
                type="password"
                size="large"
                autocomplete="off"
              />
            </a-form-item>
            <!-- 确认密码 -->
            <a-form-item
              has-feedback
              :label="$t('login.confirm_password')"
              :colon="false"
              name="newpass2"
            >
              <a-input-password
                v-model:value="formState.newpass2"
                type="password"
                size="large"
                autocomplete="off"
              />
            </a-form-item>
            <div class="btns">
              <a-button
                class="btn"
                type="primary"
                html-type="submit"
                :loading="confirmLoading"
                >{{ $t("login.ok_to_modify") }}</a-button
              >
              <!-- 确定修改 -->
            </div>
          </a-form>
        </a-tab-pane>
      </a-tabs>
      <div
        class="one-page-back"
        v-if="[1, 2, 3].includes(companyInfo.mobileModule)"
        @click="onePageBAck"
      >
        <RollbackOutlined /> {{ $t("go_back") }}
        <!--  -->
      </div>
    </div>
    <!-- 上传头像 -->
    <a-modal
      v-model:visible="showDialog"
      :title="$t('login.upload_avatar')"
      :maskClosable="false"
    >
      <VuePictureCropper
        :boxStyle="{
          width: '400px',
          height: '400px',
          backgroundColor: '#f8f8f8',
          margin: 'auto',
        }"
        :img="pic"
        :options="{
          viewMode: 1,
          dragMode: 'move',
          aspectRatio: 1,
          cropBoxResizable: false,
        }"
        :presetMode="{
          mode: 'fixedSize',
          width: 300,
          height: 300,
        }"
      />
      <template #footer>
        <a-button @click="showDialog = false">{{ $t("CM_Cancel") }}</a-button>
        <!-- 取消 -->
        <a-button type="primary" @click="getResult">{{
          $t("teacher.crop")
        }}</a-button>
        <!-- 裁切 -->
      </template>
    </a-modal>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { computed, getCurrentInstance, reactive, ref, toRefs } from "vue";
import { uploadUrl } from "@/utils/tools";
import { validatorPassword } from "@/utils/formRules";
import { validatorMobile, validatorEmail, validatorTel } from "@/utils/formRules";
import { userInfo, userInfoEdit, pwdEdit } from "@/api/user";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import moment from "moment";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import request from "@/utils/request";
import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    VuePictureCropper,
    OpenData,
  },
  setup() {
    const { t: $t } = useI18n();
    const router = useRouter();
    const ISHTSY = process.env.VUE_APP_CLIENT === "htsy";
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const formRef_userInfo = ref();
    const formRef = ref();
    const formState = reactive({
      oldpass: "",
      newpass: "",
      newpass2: "",
    });
    const state = reactive({
      currentTab: 1,
      userInfo: {},
      platformConfig: computed(() => store.getters.platformConfig),
      companyInfo: computed(() => store.getters.companyInfo),
      fileList: [],
      loadingImg: false,
      imageUrl: "",
      realName: "",
      departmentName: "",
      account: "",
      positionName:"",
      gender: 0,
      birthday: null,
      submitting: false,
      confirmLoading: false,
      pic: "",
      showDialog: false,
      hasPwd: false,
    });

    //隐藏修改密码
    if (
      state.platformConfig.platform != 26 &&
      state.platformConfig.platform != 31 &&
      state.platformConfig.platform != 41
    ) {
      state.hasPwd = true;
    }

    const getUserInfo = () => {
      userInfo().then((res) => {
        if (res.ret == 0) {
          store.dispatch("setUserInfo", res.data);
          state.imageUrl = res.data.portrait || "";
          state.gender = res.data.gender;
          state.account = res.data.account;
          state.realName = res.data.realName;
          state.departmentName = res.data.departmentName;
          state.positionName = res.data.positionName;
          state.departmentName = res.data.departmentName;
          state.birthday =
            res.data.birthday > 0
              ? moment(
                  String(res.data.birthday).substring(0, 4) +
                    "-" +
                    String(res.data.birthday).substring(4, 6) +
                    "-" +
                    String(res.data.birthday).substring(6, 8)
                )
              : null;
          state.userInfo = {
            file: "",
            departmentName: res.data.departmentName || "",
            positionName: res.data.positionName || "",
            gender: 0,
            birthday: String(res.data.birthday) || "",
            mobile: res.data.mobile || "",
            tel: res.data.tel || "",
            email: res.data.email || "",
          };
        }else{
          proxy.$message.error($t("未授权或审核未通过"));
        }
      });
    };

    let validateOldPass = async (rule, value) => {
      if (value === "") {
        return Promise.reject($t("login.password_p"));
        // 请输入密码
      } else {
        if (value.length < 8) {
          return Promise.reject($t("login.password_len"));
          // 密码不能少于8位
        }

        return Promise.resolve();
      }
    };
    let validatePass2 = async (rule, value) => {
      if (value === "") {
        return Promise.reject($t("login.confirm_password_p"));
        // 请输入确认密码
      } else if (value !== formState.newpass) {
        return Promise.reject($t("login.not_match"));
        // 两次密码不一致
      } else {
        return Promise.resolve();
      }
    };

    const rules = {
      oldpass: [
        {
          required: true,
          validator: validateOldPass,
          trigger: "change",
        },
      ],
      newpass: [
        {
          required: true,
          message: $t("login.new_password_p"),
          // 请输入新密码
          trigger: "change",
        },
        {
          validator: validatorPassword,
          trigger: "change",
        },
      ],
      newpass2: [
        {
          required: true,
          validator: validatePass2,
          trigger: "change",
        },
      ],
    };

    const updatePassword = (values) => {
      state.confirmLoading = true;
      pwdEdit({
        oldpass: proxy.$getRsaCode(formState.oldpass),
        newpass: proxy.$getRsaCode(formState.newpass),
      })
        .then((res) => {
          if (res.ret === 0) {
            proxy.$message.success($t("mine.successfully_modified"));
            formState.oldpass = "";
            formState.newpass = "";
            formState.newpass2 = "";
            formRef.value.resetFields();
          }
        })
        .finally((res) => {
          state.confirmLoading = false;
        });
    };

    const saveUserInfo = (values) => {
      state.submitting = true;
      state.userInfo.gender = state.gender || 0;
      userInfoEdit(state.userInfo)
        .then((res) => {
          if (res.ret == 0) {
            proxy.$message.success($t("Pub_Msg_SaveSuccess"));
            // 保存成功！
            getUserInfo();
          }
        })
        .finally((res) => {
          state.submitting = false;
        });
    };

    const selectDate = (date, dateString) => {
      state.userInfo.birthday = dateString;
    };

    getUserInfo();

    const uploadInput = ref(null);
    let blobURL = "";
    const selectFile = (e) => {
      blobURL = "";
      const target = e.target;
      const { files } = target;
      if (!files) return;
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        state.pic = String(reader.result);
        state.showDialog = true;
        if (!uploadInput.value) return;
        uploadInput.value.value = "";
      };
    };

    const getResult = async () => {
      const blob = await cropper.getBlob();
      const file = await cropper.getFile();
      try {
        blobURL = URL.createObjectURL(blob);
      } catch (e) {
        blobURL = "";
      }
      state.showDialog = false;
      state.imageUrl = blobURL;
      let formData = new FormData();
      formData.append("file", blob, file.name);
      request
        .post(uploadUrl + "study/me/upload/avatar", formData)
        .then((res) => {
          state.userInfo.file = res.data;
        });
    };

    const onePageBAck = () => {
      router.go(-1);
    };

    const copyText= () => {
      const input = document.createElement('input');
      input.value =  state.account; // 设置要复制的文本
      document.body.appendChild(input); // 添加input元素
      input.select(); // 选中文本
      document.execCommand('copy'); // 执行复制操作
      document.body.removeChild(input); // 移除input元素
      proxy.$message.success("文本已复制到剪贴板");
    };

    return {
      ISHTSY,
      ...toRefs(state),
      formRef_userInfo,
      formRef,
      formState,
      rules,
      saveUserInfo,
      updatePassword,
      uploadUrl,
      selectDate,
      validatorMobile,
      validatorEmail,
      validatorTel,
      uploadInput,
      selectFile,
      getResult,
      onePageBAck,
      copyText,
    };
  },
};
</script>

<style lang="less" scoped>
.ant-btn-primary {
  background: @color-theme;
  border-color: @color-theme;
}
.component-wrap {
  min-height: 720px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  padding: 14px 30px 30px 30px;
  .form-box {
    padding-top: 16px;
    .ant-form-item {
      margin-bottom: 34px;
    }
    .btns {
      border-top: 1px solid #eeeeee;
      padding-top: 30px;
      text-align: center;
      .btn {
        width: 160px;
        height: 44px;
      }
    }
    &.pwd {
      ::v-deep(.ant-form-item-explain, .ant-form-item-extra) {
        position: absolute;
        top: 0;
        right: -105%;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
      }
    }
  }
}
.avatar-box {
  width: 80px;
  height: 80px;
  border-radius: 2px;
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 0;
  overflow: hidden;
  img {
    width: 80px;
    height: 80px;
  }
  .up-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    opacity: 0;
    background-color: transparent;
    color: transparent;
    cursor: pointer;
  }
  .edit {
    // content: "修改";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 24px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: none;
  }
}
.tabs {
  position: relative;
  .one-page-back {
    position: absolute;
    right: 0;
    top: 10px;
    font-size: 18px;
    cursor: pointer;
  }
}
</style>
